// LP CONSTANTS
export const DEFAULT_OPEN_HOURS = [
  { /* Monday */
    dayOfWeek: 1,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Tuesday */
    dayOfWeek: 2,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Wednesday */
    dayOfWeek: 3,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Thursday */
    dayOfWeek: 4,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Friday */
    dayOfWeek: 5,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Saturday */
    dayOfWeek: 6,
    openTime: '08:00:00',
    closeTime: '19:00:00'
  },
  { /* Sunday */
    dayOfWeek: 7,
    openTime: '09:00:00',
    closeTime: '19:00:00'
  }
];
