/**
 * @module Session Storage
 * @description Utils for Session Storage
 */

/**
 * @function persistData
 * @description Save data to local storage
 */
export const persistData = ( key, value ) => sessionStorage.setItem( key, value );

/**
 * @function isStored
 * @description Check if data is already stored in Local Storage
 */
export const isStored = ( key ) => sessionStorage.getItem( key );

/**
 * @function deleteStored
 * @description Delete key value pair from Session Storage
 */
export const deleteStored = ( key ) => sessionStorage.removeItem( key );
