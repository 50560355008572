// DO NOT EDIT THIS FILE. THIS FILE IS GENERATED IN NEXT.CONFIG.JS
import { lazy } from "react";

const listOfPreampAssets = {
  ['Banner - CTA - Editable']: lazy(() => import('./assets/Banner - CTA - Editable')),
  ['Banner - Countdown - Editable']: lazy(() => import('./assets/Banner - Countdown - Editable')),
  ['Banner - Days Countdown - Editable']: lazy(() => import('./assets/Banner - Days Countdown - Editable')),
  ['Banner - Headline - Editable']: lazy(() => import('./assets/Banner - Headline - Editable')),
  ['Banner - Service Check Single Line']: lazy(() => import('./assets/Banner - Service Check Single Line')),
  ['Empty - Hides Base Site']: lazy(() => import('./assets/Empty - Hides Base Site')),
  ['Hero - Content Only - Phone Heavy']: lazy(() => import('./assets/Hero - Content Only - Phone Heavy')),
  ['Hero | Carousel - 200M']: lazy(() => import('./assets/Hero | Carousel - 200M')),
  ['Hero | Carousel - 200mb + Wi-Fi 7']: lazy(() => import('./assets/Hero | Carousel - 200mb + Wi-Fi 7')),
  ['Hero | Carousel - 200mb + Wi-Fi 7 + G2G']: lazy(() => import('./assets/Hero | Carousel - 200mb + Wi-Fi 7 + G2G')),
  ['Hero | Carousel - CNET Badge']: lazy(() => import('./assets/Hero | Carousel - CNET Badge')),
  ['Hero | Carousel - OFS + Wi-Fi 7']: lazy(() => import('./assets/Hero | Carousel - OFS + Wi-Fi 7')),
  ['Hero | Carousel | Redesign - Editable']: lazy(() => import('./assets/Hero | Carousel | Redesign - Editable')),
  ['Hero | Editable']: lazy(() => import('./assets/Hero | Editable')),
  ['Hero | Guided Discovery | Editable']: lazy(() => import('./assets/Hero | Guided Discovery | Editable')),
  ['Hero | Price Point | Tiered - Editable']: lazy(() => import('./assets/Hero | Price Point | Tiered - Editable')),
  ['Hero | Redesign - Editable']: lazy(() => import('./assets/Hero | Redesign - Editable')),
  ['Home - Banner - Service Check']: lazy(() => import('./assets/Home - Banner - Service Check')),
  ['Home | Modal | GDT']: lazy(() => import('./assets/Home | Modal | GDT')),
  ['Modal | Return Visitor Refresh Dual CTA']: lazy(() => import('./assets/Modal | Return Visitor Refresh Dual CTA')),
  ['Modal | Return Visitor Refresh Image']: lazy(() => import('./assets/Modal | Return Visitor Refresh Image')),
  ['Nav | Banner | Mobile - Editable']: lazy(() => import('./assets/Nav | Banner | Mobile - Editable')),
  ['Section - Trophy Case - 3 Card - Editable']: lazy(() => import('./assets/Section - Trophy Case - 3 Card - Editable')),
  ['Section - Trophy Case - Editable']: lazy(() => import('./assets/Section - Trophy Case - Editable')),
  ['Section | Bento | Fiber Education - Editable']: lazy(() => import('./assets/Section | Bento | Fiber Education - Editable')),
  ['Section | CNET | Editable']: lazy(() => import('./assets/Section | CNET | Editable')),
  ['Section | Comparison Grid']: lazy(() => import('./assets/Section | Comparison Grid')),
  ['Section | Dynamic Speed Graph | Editable']: lazy(() => import('./assets/Section | Dynamic Speed Graph | Editable')),
  ['Section | Email Capture | Editable']: lazy(() => import('./assets/Section | Email Capture | Editable')),
  ['Section | FAQs']: lazy(() => import('./assets/Section | FAQs')),
  ['Section | FAQs | FRDC redesign']: lazy(() => import('./assets/Section | FAQs | FRDC redesign')),
  ['Section | FAQs | Shop']: lazy(() => import('./assets/Section | FAQs | Shop')),
  ['Section | GDT | CTA']: lazy(() => import('./assets/Section | GDT | CTA')),
  ['Section | GDT | Editable']: lazy(() => import('./assets/Section | GDT | Editable')),
  ['Section | GDT | QUIZ']: lazy(() => import('./assets/Section | GDT | QUIZ')),
  ['Section | Offers | Editable']: lazy(() => import('./assets/Section | Offers | Editable')),
  ['Section | Plan Features']: lazy(() => import('./assets/Section | Plan Features')),
  ['Section | Product Cards | Editable']: lazy(() => import('./assets/Section | Product Cards | Editable')),
  ['Section | Product Cards | Expansion - Editable']: lazy(() => import('./assets/Section | Product Cards | Expansion - Editable')),
  ['Section | Sticky CTA']: lazy(() => import('./assets/Section | Sticky CTA')),
  ['Section | Sticky CTA | GDT']: lazy(() => import('./assets/Section | Sticky CTA | GDT')),
  ['Section | Testimonials - Editable']: lazy(() => import('./assets/Section | Testimonials - Editable')),
  ['Section | VAS - Editable']: lazy(() => import('./assets/Section | VAS - Editable')),
  ['Section | Why Frontier - Editable']: lazy(() => import('./assets/Section | Why Frontier - Editable')),
  ['Shop - Hero - Price Point - Tiered 5G']: lazy(() => import('./assets/Shop - Hero - Price Point - Tiered 5G')),
};

export default listOfPreampAssets;
