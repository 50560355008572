/**
 * @module PhoneButton
 * @description
 */
import {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import Link from 'next/link';
import { trackEvent } from '@/js/helpers/tracking';
import styles from './DynamicButton.module.css';
import { getCookie } from '@/js/utils/cookie';
import { CohesionContext } from '@red-digital/bricks';
import { formatPhoneNumber } from '@/js/helpers/fuse';

/**
 * @function PhoneButton
 * @description
 */
export const PhoneButton = ( { ...props } ) => {
  const { altPhoneNumber } = props;
  const [ amexOffer, setAmexOffer ] = useState( false );
  const { fuseNumber, defaultFuseNumber } = useContext( CohesionContext );
  const phoneProps = useRef( { } );
  const phoneNumber = altPhoneNumber || fuseNumber || defaultFuseNumber;

  delete props.href; // we need to remove the href prop otherwise we could end up going to cart
  const {
    position,
    children = <>CALL <span className="icon icon-phone-outline"/> {formatPhoneNumber( phoneNumber )}</>
  } = props;

  /**
   * @function handleClick
   * @description
   */
  const handleClick = ( event ) => {
    const data = {
      elementType: 'BUTTON',
      position,
      text: 'Phone Number',
      actionOutcome: 'CLICKTOCALL'
    };

    trackEvent( { action: 'elementClicked', data, event } );
  };

  /**
   * @function useEffect
   * @description Fuse numbers are placed before assets are done loading, so we need to place them again once this asset loads (Unless we're in the amex offer)
   */
  useEffect( () => {
    if ( getCookie( 'isAmexOffer' ) ) {
      setAmexOffer( true );
      phoneProps.current = { href: 'tel:877.837.6222' };
    }
  }, [ children ] );


  return (
    ( <Link
      href={ `tel:${ phoneNumber }` }
      onClick={ handleClick }
      data-type="phone"
      className={ `btn ${ styles.component__btn }` }
      { ...phoneProps.current }
      { ...props }>

      { amexOffer ? <>CALL <span className="icon icon-phone-outline"/> 1-877-837-6222</> : children }

    </Link> )
  );
};

export default PhoneButton;
