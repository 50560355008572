/**
 * @module Actions
 * @description
 */
import * as tracker from './trackers';

/**
 * @function log
 * @description
 */
const log = ( data, event, action ) => {
  const styles = 'font-weight: bold; color: #ff0000;';

  if ( Object.keys( data ).length ) {
    console.groupCollapsed( `%cEvent: ${ action }`, styles );
    console.table( data );
    console.groupEnd();
  } else {
    console.log( `%cEvent: ${ action }`, styles );
  }
};

export const production = {
  elementClicked: [ tracker.Tagular.elementClicked ],
  elementViewed: [ log, tracker.Tagular.elementViewed ],
  fieldInputted: [ tracker.Tagular.fieldInputted ],
  formStarted: [ log, tracker.Tagular.formStarted ],
  formSubmitted: [ log, tracker.Tagular.formSubmitted, tracker.Adobe.formSubmitted ],
  pageView: [ tracker.Tagular.pageViewed ],
  customerCaptured: [ tracker.Tagular.customerCaptured ],
  identify: [ tracker.Tagular.identify ]
};

export const development = {
  elementClicked: [ log, tracker.Tagular.elementClicked ],
  elementViewed: [ log, tracker.Tagular.elementViewed ],
  fieldInputted: [ log, tracker.Tagular.fieldInputted ],
  formStarted: [ log, tracker.Tagular.formStarted ],
  formSubmitted: [ log, tracker.Tagular.formSubmitted, tracker.Adobe.formSubmitted ],
  pageView: [ log, tracker.Tagular.pageViewed ],
  customerCaptured: [ log, tracker.Tagular.customerCaptured ],
  identify: [ log, tracker.Tagular.identify ]
};
