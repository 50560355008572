/**
 * @module Persist Cart State
 * @description
 */
import { useEffect, useState } from 'react';
import { isStored, persistData } from '@/js/utils/session-storage';
import { setCookie } from '@/js/utils/cookie';
import productHashMap from '@/js/constants/cart';

/**
 * @function useCartState
 * @description
 */
export const useCartState = () => {
  const [ clickThrough, setClickThrough ] = useState( '/buy' );
  const [ cartStore, setCartStore ] = useState( null );

  // Check for persisted cart state in session storage
  useEffect( () => {
    if ( cartStore ) return;

    const persistedState = isStored( 'persist:fuel' );
    const store = JSON.parse( persistedState );

    /*
     Do not update click through if:
     a) there is no cart state in session storage (means the user has not entered cart during the current session)
     b) there is no address key in the cart state (means the user has not submitted an address to run serviceability)
     c) there are no available products in the cart state (means the user has not been through getProducts)
    */
    if ( ! store || ! store?.address?.install?.addressKey || ! store?.products?.available ) {
      return;
    }

    // Once meeting the above criteria, we can send a user to /buy/plan-package when they click a cart CTA
    const planPackage = `${ clickThrough }/plan-package`;
    setClickThrough( planPackage );

    // Add variable to cart state signaling that a user continued their cart session after returning to landing page
    setCartStore( { ...store, landingPage: { persisted: true } } );
    persistData( 'persist:fuel', JSON.stringify( { ...store, landingPage: { persisted: true } } ) );
  }, [ cartStore, clickThrough ] );

  // Update persisted flag and send user to /buy to run serviceability
  const submitDifferentAddress = () => {
    setCartStore( ( store ) => ( { ...store, landingPage: { persisted: false } } ) );
    persistData( 'persist:fuel', JSON.stringify( { ...cartStore, landingPage: { persisted: false } } ) );

    const buyPath = clickThrough.replace( '/plan-package', '' ); // Strip off /plan-package in case they had a persisted cart state but different address
    window.location.href = buyPath;
  };


  // When 'Select Plan' CTA is clicked, set the product ID in session storage for use in cart
  const selectProduct = ( product ) => {
    const productHash = productHashMap[ product ];

    if ( ! productHash ) return;
    persistData( 'selectedProductId', productHash );
    setCookie( 'selectedProductId', productHash, 30 ); // Set cookie for cart preamp audience, expires in 30 minutes
  };

  return {
    clickThrough, cartStore, submitDifferentAddress, selectProduct
  };
};

export default useCartState;
