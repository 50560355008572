/**
 * @module Helpers
 * @description Some handy helpers
 */
const fastlyBase = process.env.imagePath;

/**
 * @function generateIdNumber
 * @description Generate 20 digit random integer
 */
export const generateIdNumber = () => Math.round( Math.random() * 10000000 ).toString() + new Date().getTime();

/**
 * @function getAsset
 * @description Get asset from basePath
 */
export const getAsset = ( asset = '' ) => `${ process.env.basePath || '' }${ asset }`;

/**
 * @function localImage
 * @description Our local image loader
 */
export const localImage = ( asset ) => getAsset( asset.src );

/**
 * @function fastly
 * @description Our local image loader
 */
export const fastly = ( asset ) => {
  const base = new URL( `${ fastlyBase }${ asset.src }` );
  const params = base.searchParams;

  if ( asset.src.endsWith( '.svg' ) ) return base.href;

  if ( asset.width ) {
    params.set( 'width', asset.width );
  }

  if ( asset.height ) {
    params.set( 'height', asset.height );
  }

  return base.href;
};

/**
 * @function fastlyBlur
 * @description Our fastly blur helper
 */
export const fastlyBlur = ( src ) => `${ fastlyBase }${ src }?width=10`;
