/**
 * @module ContentProvider HOC
 * @description
 */
import {
  createContext, useContext, useEffect, useState
} from 'react';
import { FuelContext } from '@/providers/fuel-provider';
import useContentProvider from './hooks/useContentProvider';
import { getFiberProducts, getFeaturedProduct } from './utils/products';

/**
 * @function ContentProviderContext
 * @description
 */
export const ContentProviderContext = createContext();

/**
 * @function withContentProvider
 * @description
 */
export const withContentProvider = ( { App } ) => {
  const ContentProviderHOC = ( props ) => {
    const {
      products, disclosures, isLoading, isError
    } = useContentProvider();
    const { monarchProduct } = useContext( FuelContext );

    const [ legal, setLegal ] = useState();
    const [ featuredProduct, setFeaturedProduct ] = useState( null );
    const [ productLoaded, setProductLoaded ] = useState( false );
    const [ fiberProducts, setFiberProducts ] = useState( null );

    useEffect( () => {
      if ( ! products || ! monarchProduct ) return;

      setFiberProducts( getFiberProducts( products ) );
      setFeaturedProduct( getFeaturedProduct( products, monarchProduct ) );
      setProductLoaded( true );
    }, [ products, monarchProduct ] );

    return (
      <ContentProviderContext.Provider value={ {
        disclosures,
        setLegal,
        legal,
        products: fiberProducts,
        featuredProduct,
        productLoaded,
        isLoading,
        isError
      } }>
        <App { ...props } />
      </ContentProviderContext.Provider>
    );
  };

  return ContentProviderHOC;
};
