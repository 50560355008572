// CART PRODUCTS IDS
export const FIBER_200 = 'd03c8fa0-4464-4ee7-a979-0700e2f5d290';
export const FIBER_500 = 'c75fb0df-6d48-4a06-bb10-3ee47a8d3d28';
export const FIBER_GIG = '2b09989e-1696-4cc2-b13c-7e0950b74588';
export const FIBER_2GIG = '67e4d13f-65f8-485b-8ebc-9c922e72c1a2';
export const FIBER_5GIG = '4a7c0a86-9b8d-4b5f-b60f-10849155f5c0';
export const FIBER_7GIG = '4fbc7f33-616d-4923-bbb7-e88e46658917';

export const productHashMap = {
  fiber200: FIBER_200,
  fiber500: FIBER_500,
  fiberGig: FIBER_GIG,
  fiber2Gig: FIBER_2GIG,
  fiber5Gig: FIBER_5GIG,
  fiber7Gig: FIBER_7GIG
};

export const productKeyMap = {
  'fiber-200m': 'fiber200',
  'fiber-500m': 'fiber500',
  'fiber-gig': 'fiberGig',
  'fiber-2-gig': 'fiber2Gig',
  'fiber-5-gig': 'fiber5Gig',
  'fiber-7-gig': 'fiber7Gig'
};

export default productHashMap;
