/**
 * @module Tracking
 * @description
 */
import { development, production } from './actions';

const trackingActions = process.env.environment === 'production' ? production : development;

/**
 * @function trackEvent
 * @description
 */
export const trackEvent = ( { action, data = {}, event = {} } ) => {
  if ( action in trackingActions ) {
    trackingActions[ action ].forEach( ( trackMethod ) => trackMethod( data, event, action ) );
  }
};
