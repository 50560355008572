/* eslint-disable */
/**
 * Adobe Tracking service
 */

/**
 * @function fireAdobeEvent
 * @description Fire an adobe event
 */
const fireAdobeEvent = ({ data, eventType, interaction }) => {
  if (
    typeof window.s_dtm !== "undefined" &&
    typeof window.s_dtm.trackData === "function"
  ) {
    window.s_dtm.trackData(data, eventType, interaction);
  }
};

/**
 * @function formSubmitted
 * @description Tracks whenever a search form submitted
 */
export const formSubmitted = () => {
  const obj = {};
  obj.events = "event14";
  obj.eVar14 = "check availability";
  fireAdobeEvent({ data: obj, eventType: "site interaction" });
};