/**
 * @module Error Boundary
 * @description Component to display when app crashes
 */
import { useEffect } from 'react';
import Image from 'next/image';
import PhoneButton from '@/blocks/Buttons/PhoneButton';
import { trackEvent } from '@/js/helpers/tracking';
import { fastly } from '@/js/utils/helpers';

/**
 * @function Component
 * @description
 */
const Component = () => {
  useEffect( () => {
    const data = {
      elementType: 'PAGE',
      position: 'ERROR BOUNDARY'
    };

    trackEvent( { action: 'elementViewed', data } );
  }, [] );

  return (
		<>
			<div className="component">
				<div className="l-container">
					<Image
						alt="Frontier Logo"
						src="/site-logo-rebrand_red.svg"
						loading="lazy"
						loader={ fastly }
						width="32"
						height="32"
					/>
					<h3>There's a problem loading this page</h3>
					<p>There’s a technical problem that has prevented this page from loading. Call our experts to complete your order.</p>
					<PhoneButton position="ERROR BOUNDARY" />
				</div>
			</div>
    </>
  );
};

export default Component;
