/**
 * @module NewRelic
 * @description Utils for New Relic alerting
 */

/**
 * @function addPageAction
 * @description fire page action
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-page-action
 */
export const addPageAction = ( eventName, eventData = {} ) => {
  const { newrelic } = window;
  if ( typeof newrelic === 'object' ) {
    newrelic.addPageAction( eventName, eventData );
    newrelic.interaction().save();
  }
};

/**
 * @function setCustomAttribute
 * @description set custom attribute for PageView
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/set-custom-attribute
 */
export const setCustomAttribute = ( attributeName, attributeValue ) => {
  const { newrelic } = window;
  if ( typeof newrelic === 'object' ) {
    newrelic.setCustomAttribute( attributeName, attributeValue );
    newrelic.interaction().save();
  }
};
